<template>
    <div class="container">
        <div class="body">
            <div class="title">订单记录</div>
            <div class="list" v-for="(item, index) of orderList" :key="index">
                <div class="order-sn">
                    <div class="item">订单编号：{{item.sn}}</div>
                    <div class="status" :class="item.status | payStatusColorFilter">{{item.status | orderStatusFilter}}
                    </div>
                </div>
                <div class="info">
                    <div class="row">
                        <img src="@/assets/images/service/year.png" alt="" class="img"
                            v-if="item.order_info.subscribe_count >= 3">
                        <img src="@/assets/images/service/month.png" alt="" class="img" v-else>
                        <div class="right">
                            <div class="title" v-if="item.order_info.subscribe_count >= 3">梨子助教多年版</div>
                            <div class="title" v-else>梨子助教年费版</div>
                            <div class="tag">适合成长型机构经营</div>
                            <div class="price">¥{{item.order_info.unit_price / 100}}/年</div>
                        </div>
                    </div>
                    <div class="time">
                        <div class="text-1">订阅时长</div>
                        <div class="text-2">{{item.order_info.subscribe_count}}/年</div>
                    </div>
                    <div class="time">
                        <div class="text-1">学校数量</div>
                        <div class="text-2">{{item.order_info.school_count}}/个</div>
                    </div>
                    <div class="time total">
                        <div class="text-1">总计</div>
                        <div class="text-1">¥{{item.charge | numFixed}}</div>
                    </div>
                </div>
                <div class="btn" v-if="item.status !== 200">
                    <!-- <el-button>取消订单</el-button> -->
                    <el-button type="success" @click="routerChange(1, item.id)">立即支付</el-button>
                </div>
                <div class="btn" v-else>
                    <el-button type="success">立即使用</el-button>
                </div>
            </div>
            <div v-if='!orderList.length'>
                <no-data></no-data>
            </div>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
                    :current-page="currentPage" @size-change="pageSizeChange" @current-change="pageChange">
                </el-pagination>
            </div>

        </div>

    </div>
</template>

<script>
import Core from 'core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            orderList: [],
            currentPage: 1,
            pageSize: 3,
            total: 0,
            org_id: Core.Data.getBelong()
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getOrderList();
    },
    methods: {
        pageChange(page) {
            // 页码改变
            this.currentPage = page;
            this.getOrderList();
        },
        pageSizeChange(size) {
            // 列表信息行数改变
            this.pageSize = size;
            this.getOrderList();
        },
        getOrderList() {
            Core.Api.OrgSystem.orderList(
                20,
                this.currentPage,
                this.pageSize
            ).then((res) => {
                this.total = res.count;
                this.orderList = res.list;
            });
        },
        routerChange(type, id) {
            switch (type) {
                case 1:
                    this.$router.push({
                        path: '/system-service/service-detail',
                        query: {
                            active: 2,
                            id: id
                        }
                    });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .body {
        margin: 30px;
        padding: 20px;
        background: white;
        display: flex;
        flex-direction: column;

        .title {
            font-size: 16px;
            font-weight: 500;
            color: #363d42;
        }
        .list {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            border: 1px solid #e3eaef;
            border-radius: 3px;

            .order-sn {
                display: flex;
                justify-content: space-between;
                height: 50px;
                align-items: center;
                border-bottom: 1px solid #e3eaef;
                .item {
                    margin-left: 28px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #262626;
                }
                .status {
                    margin-right: 25px;
                    font-size: 16px;
                    font-weight: 500;
                }
                .background-1890FF {
                    color: #ff7654 !important;
                }
                .background-52C41A {
                    color: #74be5f !important;
                }
                .background-F6BD16 {
                    color: #f6bd16 !important;
                }
                .background-BBBBBB {
                    color: #bbbbbb !important;
                }
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                .row {
                    display: flex;
                    flex-direction: row;
                    margin-left: 28px;
                    .img {
                        width: 105px;
                        height: 105px;
                    }
                    .right {
                        margin-left: 16px;
                        .title {
                            font-size: 20px;
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.85);
                            margin-top: 5px;
                        }
                        .tag {
                            font-size: 14px;
                            font-weight: 400;
                            color: #666666;
                            margin-top: 8px;
                        }
                        .price {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 13px;
                        }
                    }
                }
                .time {
                    display: flex;
                    flex-direction: column;
                    .text-1 {
                        margin-bottom: 15px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #262626;
                    }
                    .text-2 {
                        font-size: 14px;
                        font-weight: 400;
                        color: #666666;
                    }
                    &.total {
                        margin-right: 22px;
                        display: flex;
                        align-items: flex-end;
                    }
                }
            }
            .btn {
                display: flex;
                justify-content: flex-end;
                margin-right: 25px;
                margin-bottom: 20px;

                button {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
        .pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
        }
    }
}
</style>